<template>
  <component :is="icon" :style="{ transform: `rotate(${rotate}deg` }" />
</template>

<script setup>
import { defineAsyncComponent, shallowRef, watch } from 'vue';

const { name, rotate } = defineProps({
  name: {
    type: String,
    required: true,
  },
  rotate: {
    type: [String, Number],
    default: 0,
  },
});

const icon = shallowRef('');

icon.value = defineAsyncComponent(() => import(`../../assets/img/svg/${name}.vue`));

watch(
  () => name,
  (newValue) => {
    console.log('watch SVG-icon');
    icon.value = defineAsyncComponent(() => import(`../../assets/img/svg/${newValue}.vue`));
  },
);
</script>
