import { createApp } from 'vue';
import App from './App.vue';
import i18n from '@/i18n';
import TheContainer from '@/components/TheContainer.vue';
import '@/assets/styles/normalize.scss';
import '@/assets/styles/main.scss';
const app = createApp(App);

app.component('TheContainer', TheContainer);
app.use(i18n);

app.mount('#app');
