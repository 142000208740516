<template>
  <component :is="$attrs.href ? 'a' : tag" :class="[mainClass, color, 'title title-sm']">
    {{ label }}
    <svg-icon v-if="icon" :name="icon.name" :rotate="icon.rotate" />
  </component>
</template>

<script setup>
import { computed } from 'vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';

const { tag, color, label, view, icon } = defineProps({
  tag: {
    type: String,
    default: 'button',
  },
  view: {
    type: String,
    default: 'button',
  },
  color: {
    type: String,
    default: 'black',
  },
  label: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
});

const mainClass = computed(
  () =>
    ({
      button: 'btn',
      text: 'textBtn',
      icon: 'icon',
    })[view],
);
</script>

<style lang="scss" scoped>
@import '@/assets/styles/main.scss';
.btn {
  display: flex;
  padding: 12px 24px;
  border-radius: 100px;

  border: none;

  cursor: pointer;

  transition: all 0.25s ease;

  min-width: 140px;

  justify-content: center;
  align-items: center;

  min-height: 48px;

  &.violet {
    font-family: 'Nunito', sans-serif;
    background: $violet;
    color: #fff;

    &:hover {
      background: #9a69e9;
    }
  }

  &.black {
    font-family: 'Montserrat', sans-serif;
    background: $black;
    color: #fff;

    &:hover {
      background: #3b3b3b;
    }
  }

  &.yellow {
    font-family: 'Montserrat', sans-serif;
    background: $yellow;
    color: $black;

    padding: 18px 24px;

    &:hover {
      background: #ffdc53;
    }
  }
}

.textBtn {
  display: flex;
  padding: 12px 24px;
  border-radius: 100px;

  border: none;

  cursor: pointer;

  transition: all 0.25s ease;

  min-width: 140px;

  justify-content: center;
  align-items: center;

  min-height: 48px;

  gap: 5px;

  font-family: 'Montserrat', sans-serif;

  &:hover {
    gap: 7px;
  }
}

.icon {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

a {
  width: fit-content;
  display: block;
  color: inherit;
}
</style>
