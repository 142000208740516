import { computed, nextTick, ref } from 'vue';
import i18n from '@/i18n/index.js';

export const useLanguageSwitcher = () => {
  const usersLanguage = ref(import.meta.env.VITE_DEFAULT_LOCALE);
  const supportedLocales = computed(() => import.meta.env.VITE_SUPPORTED_LOCALES.split(','));

  const loadLocale = async (locale) => {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`@/i18n/locales/${locale}.json`);
      i18n.global.setLocaleMessage(locale, messages.default);
    }

    return nextTick();
  };
  const setCurrentLanguage = () => {
    const lang = window.location.pathname.split('/')[1] || 'en';

    const isLangSupported = supportedLocales.value.includes(lang);

    const browserLanguage = isLangSupported ? lang : import.meta.env.VITE_DEFAULT_LOCALE;

    usersLanguage.value = browserLanguage;
    loadLocale(usersLanguage.value);

    i18n.global.locale.value = usersLanguage.value;
  };

  return {
    usersLanguage,
    setCurrentLanguage,
  };
};
