<template>
  <div class="footer desktop">
    <the-container class="footer__container">
      <div class="footer__body">
        <h2 class="footer__title title title-xl">
          {{ $t('footer.title') }}
        </h2>
        <base-button
          tag="a"
          :label="$t('global.buttons.start')"
          color="yellow"
          class="footer__button"
        />
        <ul class="footer__list">
          <li v-for="link of $tm('footer.links')" :key="link.id" class="footer__item">
            <a :href="links[link.id]" class="footer__link subtitle subtitle-sm">
              {{ link.label }}
            </a>
          </li>
        </ul>
        <p class="footer__contacts title title-xs">
          <span class="footer__contacts-company">
            {{ $t('footer.company') }}
          </span>
          {{ $t('footer.address') }}
        </p>
      </div>
      <base-img alt="iphone" :src="{ name: $t('footer.image') }" class="footer__image" />
    </the-container>
  </div>
  <div class="footer mob">
    <the-container class="footer__container">
      <div class="footer__body">
        <h2 class="footer__title title title-xl">
          {{ $t('footer.title') }}
        </h2>
        <base-button
          tag="a"
          :label="$t('global.buttons.start')"
          color="yellow"
          class="footer__button"
        />
        <ul class="footer__list">
          <li v-for="link of $tm('footer.links')" :key="link.id" class="footer__item">
            <a :href="links[link.id]" class="footer__link subtitle subtitle-sm">
              {{ link.label }}
            </a>
          </li>
        </ul>
      </div>

      <base-img
        alt="iphone"
        :src="{ name: `mob-${$t('footer.image')}` }"
        class="footer__image-mob"
      />
    </the-container>
    <the-container class="footer__container-mob">
      <p class="footer__contacts-mob title title-xs">
        <span class="footer__contacts-company">
          {{ $t('footer.company') }}
        </span>
        {{ $t('footer.address') }}
      </p>
    </the-container>
  </div>
</template>

<script setup>
import TheContainer from '@/components/TheContainer.vue';
import BaseImg from '@/components/shared/BaseImg.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
const links = {
  terms: import.meta.env.VITE_SERVICE_URL + '/terms',
  privacy: import.meta.env.VITE_SERVICE_URL + '/privacy',
  support: import.meta.env.VITE_SERVICE_URL + '/support',
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/main.scss';

.footer {
  font-family: 'Nunito', sans-serif;

  &.desktop {
    background: #222;
  }

  &__container {
    padding-top: 35px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: #222;
    width: 100%;

    &-mob {
      margin: 0;
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  &__title {
    color: #fff;

    @media (max-width: 768px) {
      font-size: 28px;
    }
  }

  &__image {
    max-width: 300px;

    &-mob {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 180px;
      height: auto;

      @media (max-width: 768px) {
        max-width: 130px;
      }
    }
  }

  &__body {
    height: fit-content;
    margin: auto;
    padding-bottom: 40px;

    @media (max-width: 1024px) {
      margin: 0;
      max-width: 80%;
    }
  }

  &__list {
    display: flex;
    align-items: center;

    width: fit-content;

    list-style: none;

    margin-top: 85px;

    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      &:after {
        content: '|';
        margin: 0 5px;
        display: block;
        color: #fff;
      }
    }
  }

  &__link {
    color: #fff;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }

  &__button {
    margin-top: 40px;

    @media (max-width: 768px) {
      margin-top: 32px;
      font-size: 12px;
    }
  }

  &__contacts {
    color: $gray;
    display: flex;
    align-items: center;
    gap: 25px;

    margin-top: 20px;

    &-mob {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;

      color: $gray;

      @media (max-width: 768px) {
        font-size: 10px;
      }

      @media (max-width: 1024px) {
        max-width: 65%;
      }
    }
  }
}

.mob {
  position: relative;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
}

.desktop {
  @media (max-width: 1024px) {
    display: none;
  }
}
</style>
