<template>
  <div class="reviews">
    <the-container>
      <h2 class="reviews__title title title-xlg">
        {{ $t('comments.title') }}
      </h2>
    </the-container>
    <the-container class="reviews__swiper">
      <swiper :spaceBetween="30" :modules="[Pagination]" @swiper="swipeController = $event">
        <swiper-slide
          v-for="review of $tm('comments.items')"
          :key="review.id"
          class="reviews__slide"
        >
          <base-img
            :alt="review.name"
            :src="{ name: review.avatar, dir: 'reviews', subdir: 'avatars' }"
            class="reviews__avatar"
          />
          <div class="reviews__body">
            <p class="reviews__text subtitle subtitle-lg">
              {{ review.text }}
            </p>
            <h2 class="reviews__name subtitle subtitle-md">
              {{ review.name }}
            </h2>
          </div>
        </swiper-slide>
      </swiper>
      <svg-icon name="quote" class="reviews__quote-icon" />
    </the-container>
    <div class="reviews__actions-wrapper">
      <the-container>
        <div class="reviews__actions">
          <base-button view="icon" :icon="{ name: 'arrow', rotate: 180 }" @click="handlePrev" />
          <base-button view="icon" :icon="{ name: 'arrow' }" @click="handleNext" />
        </div>
      </the-container>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import BaseImg from '@/components/shared/BaseImg.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import { ref } from 'vue';
import SvgIcon from '@/components/shared/SvgIcon.vue';

const swipeController = ref(null);
const handleNext = () => {
  swipeController.value.slideNext();
};

const handlePrev = () => {
  swipeController.value.slidePrev();
};
</script>

<style lang="scss" scoped>
.reviews {
  &__title {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    z-index: 1;
    margin: 0 auto;
    margin-bottom: 80px;
    max-width: 600px;

    @media (max-width: 768px) {
      font-size: 40px;
      margin-bottom: 40px;
    }

    &:after {
      content: '';
      background: url('@/assets/img/reviews-line.png');
      background-size: cover;
      background-repeat: no-repeat;

      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 90%;
      width: 280px;
      height: 22px;
      z-index: -1;
    }
  }

  &__slide {
    display: flex;
    gap: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 768px) {
      gap: 16px;
    }
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 128px;
    max-width: 128px;
    border-radius: 50%;

    @media (max-width: 768px) {
      min-width: 40px;
      max-width: 40px;
    }
  }

  &__text {
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__name {
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  &__swiper {
    position: relative;
    padding-bottom: 90px;

    @media (max-width: 768px) {
      padding-bottom: 32px;
    }
  }

  &__actions {
    display: flex;
    width: fit-content;
    gap: 48px;

    & button {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      transition: background 0.25s ease;

      @media (min-width: 481px) {
        &:hover {
          background: #222;

          :deep(svg path) {
            fill: #fff;
          }
        }
      }
    }

    &-wrapper {
      border-top: 2px solid #222;
      padding-top: 25px;
      padding-bottom: 80px;
    }
  }

  &__quote-icon {
    position: absolute;
    bottom: 0;
    right: 80px;

    @media (max-width: 768px) {
      right: 0;
    }
  }
}
</style>
