<template>
  <default-layout>
    <the-container>
      <block-main />
    </the-container>
    <block-advantages />
    <block-features />
    <the-container>
      <block-customers />
    </the-container>
    <block-reviews />
  </default-layout>
</template>

<script setup>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BlockMain from '@/components/Block/BlockMain.vue';
import BlockAdvantages from '@/components/Block/BlockAdvantages.vue';
import TheContainer from '@/components/TheContainer.vue';
import BlockFeatures from '@/components/Block/BlockFeatures.vue';
import BlockCustomers from '@/components/Block/BlockCustomers.vue';
import BlockReviews from '@/components/Block/BlockReviews.vue';
import { useLanguageSwitcher } from '@/composables/useLanguageSwitcher.js';

const { setCurrentLanguage } = useLanguageSwitcher();

setCurrentLanguage();

document.addEventListener('DOMContentLoaded', () => {
  const funnelLinks = document.querySelectorAll('a:not([href])');
  const param = location.search;

  const FUNNEL_LINK = import.meta.env.VITE_APP_FUNNEL_LINK;
  const SERVICE_SIGN_IN_URL = import.meta.env.VITE_SERVICE_URL + '/auth/sign-in';

  funnelLinks.forEach((item) => {
    item.href = item.classList.contains('sign-in-redirect')
      ? `${SERVICE_SIGN_IN_URL}${param}`
      : `${FUNNEL_LINK}${param}`;
  });
});
</script>
